$sidebar-bg-color: #ac4142;
$link-color: #ac4142;
$sidebar-sticky: false;
@import "hydeout";

.post-list {
  list-style-type: none;
}

.post-list .post-meta {
  float: right;
}

.post-meta .post-categories, .post-body .post-tags  { 
  display: none;
}

#sidebar a:hover {
  text-decoration: none;
}

.gist-file {
  max-height: 20rem;
  overflow: auto;
}